import gsap from 'gsap/all'

export class Preloader {
  constructor(){
    this.el = document.querySelector('.preloader')
    this.event = new CustomEvent('afterLoad')
  }

  fadeIn = (callback) => {
    gsap.to(this.el, {
      autoAlpha: 1,
      pointerEvents: 'auto',
      duration: 0.3,
      onStart: () => {
        this.el.classList.remove('hidden')
      },
      onComplete: () => {
        callback()
      }
    })
  }

  fadeOut = (callback) => {
    gsap.to(this.el, {
      autoAlpha: 0,
      duration: 0.3,
      pointerEvents: 'none',
      onComplete: () => {
        this.el.classList.add('hidden')
        callback()
      }
    })
  }

  out = () => {
    gsap.to(this.el, {
      autoAlpha: 0,
      pointerEvents: 'none',
      onComplete: () => {
        window.dispatchEvent(this.event)
        if(window.matchMedia('(min-width: 768px)').matches){
          this.el.remove()
        }else{
          this.el.classList.add('hidden')
        }
      }
    })
  }
}