import "../css/app.pcss";
import router from "./router";
import { Menu } from "./components/Menu";
import { Footer } from "./components/Footer";
import { Preloader } from "./components/Preloader";
import swup from "./components/Swup";
import Cookies from "js-cookie";
import "lazysizes";
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig = {
  loadMode: 3,
  preloadAfterLoad: true,
  expFactor: 2,
  expand: window.innerHeight,
};

let restaurant = Cookies.get("restaurant");
if (restaurant && restaurant !== "undefined") {
  restaurant = JSON.parse(restaurant);
  const content = document.querySelector("#content-wrapper");
  if (content && content.dataset.module == "Home")
    window.location.href = `${window.location.protocol}//${window.location.host}/${restaurant.slug}`;
}

window.pauseAnimation = false;

const menu = new Menu(document.querySelector(".navigation"));
const footer = new Footer(document.querySelector(".footer"));

window.preloader = new Preloader();
window.addEventListener("load", async () => {
  // init wiper for page transitions
  if (window.matchMedia("(min-width: 768px)").matches) {
    const { Wipe } = await import("./components/Wipe");
    window.webGLWipe = new Wipe(document.querySelector("#wipe"));
    window.webGLWipe.init();
  } else {
    window.webGLWipe = {
      out: (callback) => window.preloader.fadeIn(callback),
      in: (callback) => window.preloader.fadeOut(callback),
    };
  }

  if (restaurant) {
    const openHours = document.querySelector(".open-hours-item");
    if (openHours) openHours.classList.remove("hidden");
  }

  // initialize the current route class
  const currentRoute = router(document.querySelector("#content-wrapper"), menu);
  footer.init();
  // init swup/page transitions/pjax
  swup(menu, currentRoute);
});
