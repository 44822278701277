import Swup from 'swup'
import SwupJsPlugin from '@swup/js-plugin'
import { gsap, ScrollTrigger, ScrollToPlugin } from 'gsap/all'
import router from '../router'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

let route = null
gsap.defaults({
  duration: 0.3,
  ease: 'power2'
})
export default (menu, currentClass) => {
  // const preloader = document.querySelector('.preloader')
  const options = [
    {
      from: '(.*)', // meaning any
      to: '(.*)', // meaning any
      out: async (next) => {
        menu.close()
        if(!route) route = await currentClass
        window.webGLWipe.out(() => {
          route.destroy()

          if(window.bodyScrollBar){
            window.bodyScrollBar.scrollTo('top', { duration: 0, disableLerp: true })
          }else{
            gsap.set(window, { scrollTo: 0 })
          }
          next()
        })
      },
      in: async (next) => {
        menu.setActiveGlobal()
        const content = document.querySelector('.content-main')
        content.classList.add('invisible')
        route = await router()
        content.classList.remove('invisible')
        menu.checkActiveLocation()
        window.webGLWipe.in(() => {
          ScrollTrigger.refresh()
          next()
        })
      }
    }
  ]

  const swup = new Swup({
    linkSelector: 'a[href^="/"]:not([data-no-swup]):not([href^="mailto"]):not([href^="tel"]):not([target="_blank"]):not([href^="#"]), a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([target="_blank"]):not([href^="mailto"]):not([href^="tel"]):not([href^="#"])',
    plugins: [new SwupJsPlugin(options)],
    containers: ['.content-main', '#outside'],
    animateHistoryBrowsing: true,
    cache: true
  })

  window.swup = swup
}