import { gsap, ScrollTrigger } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)

export class Footer {
  constructor(el){
    this.el = el
    this.menus = this.el.querySelector('.menus')
  }

  init = () => {
    if(window.matchMedia('(min-width: 768px)').matches){
      const starItems = this.el.querySelectorAll('.star-anim')
      starItems.forEach(item => {
        const svg = item.querySelector('.star .star-stroke')
        item.anim = gsap.fromTo(svg, {
          drawSVG: '0% 0%',
        },{
          drawSVG: '0% 100%',
          duration: 0.6,
          ease: 'power2',
          paused: true,
        })
        item.onmouseenter = this.tbenter
        item.onmouseleave = this.tbleave
      })
  
      this.footerAppear()
    }
  }

  tbenter = (e) => {
    const current = e.currentTarget
    current.anim.play()
  }

  tbleave = (e) => {
    const current = e.currentTarget
    current.anim.reverse()
  }

  footerAppear = () => {
    gsap.fromTo(this.el.querySelector('.footer-inner'), {
      height: 0
    },{
      height: '100vh',
      scrollTrigger: {
        trigger: this.el,
        scrub: 1,
        start: 'top bottom',
        end: 'top top',
        onEnter: () => {
          document.querySelector('#menu-button').classList.add('up')
          gsap.to(document.querySelectorAll('.navigation>.inner .top-bar-item, .navigation>.inner .menu-text'), {
            autoAlpha: 0,
            x: 75,
            ease: 'power2.out',
            duration: 0.3
          })

          document.querySelector('.navigation>.inner').classList.add('reached-bottom')
        },
        onLeaveBack: () => {
          document.querySelector('#menu-button').classList.remove('up')
          document.querySelector('.navigation>.inner').classList.remove('reached-bottom')
          gsap.to(document.querySelectorAll('.navigation>.inner .top-bar-item, .navigation>.inner .menu-text'), {
            autoAlpha: 1,
            x: 0,
            ease: 'power2.out',
            duration: 0.3
          })
        }
      }
    })
  }
}