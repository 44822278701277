import { gsap, DrawSVGPlugin, ScrollTrigger, ScrollToPlugin } from 'gsap/all'
import Cookies from 'js-cookie'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

gsap.registerPlugin(DrawSVGPlugin)
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export class Menu {
  constructor(el){
    this.el = el
    this.topBar = this.el.querySelector('.inner')
    this.topBarMenu = this.el.querySelector('.top-bar-menu')
    this.button = this.el.querySelector('#menu-button')
    this.menus = this.el.querySelector('.menus')
    this.location =  this.el.querySelector('.current-location')
  }

  init = () => {
    if(window.matchMedia('(min-width: 768px)').matches){
      const starItems = this.el.querySelectorAll('.star-anim')
      starItems.forEach(item => {
        const svg = item.querySelector('.star .star-stroke')
        item.anim = gsap.fromTo(svg, {
          drawSVG: '0% 0%',
        },{
          drawSVG: '0% 100%',
          duration: 0.6,
          ease: 'power2',
          paused: true,
        })
        item.onmouseenter = this.tbenter
        item.onmouseleave = this.tbleave
      })
    }

    this.button.onclick = this.menuButtonClick
    this.menuAnim()

    this.topBarHide()
    this.location.addEventListener('click', this.locationSwitchClick)
    this.checkActiveLocation()
  }

  locationSwitchClick = () => {
    gsap.to(this.el.querySelector('.menus-inner'), {
      x: '-100%',
      duration: 0.3,
      ease: 'power2'
    })
    gsap.to(this.location, {
      y: '100%',
      duration: 0.2,
      ease: 'power2.in'
    })
    gsap.to(this.menus, {
      scrollTo: 0,
      duration: 0.3,
      ease: 'power2'
    })
    this.button.classList.add('locations-open')
  }

  tbenter = (e) => {
    const current = e.currentTarget
    current.anim.play()
  }

  tbleave = (e) => {
    const current = e.currentTarget
    current.anim.reverse()
  }

  menuButtonClick = () => {
    if(this.button.classList.contains('up')){
      if(window.bodyScrollBar){
        window.bodyScrollBar.scrollTo('.footer', {
          offset: -window.innerHeight-10
        })
      }else{
        gsap.to(window, {
          scrollTo: {
            y: '.footer',
            offsetY: window.innerHeight+10
          }
        })
      }
    }else if(this.button.classList.contains('locations-open')){
      gsap.to(this.el.querySelector('.menus-inner'), {
        x: '0%',
        duration: 0.3,
        ease: 'power2'
      })
      gsap.to(this.location, {
        y: '0%',
        duration: 0.2,
        ease: 'power2.in'
      })
      this.button.classList.remove('locations-open')
    }else{
      this.button.classList.toggle('open')
  
      if(this.button.classList.contains('open')) {
        this.menuTimeline.play()
        if(window.bodyScrollBar){
          window.bodyScrollBar.stop()
        }else{
          disablePageScroll()
        }
        this.topBar.classList.add('bg-dark-grey')
      }else{
        this.close(true)
      }
    }
  }

  close = (skipCheck = false) => {
    if(this.button.classList.contains('open') || skipCheck){
      this.button.classList.remove('open', 'locations-open')
      if(this.topBar.classList.contains('pt-8')){
        this.topBar.classList.remove('bg-dark-grey')
      }
      gsap.to(this.topBar.querySelectorAll('.top-bar-item, .menu-text'), {
        autoAlpha: 1,
        x: 0,
        ease: 'power2.out',
        duration: 0.3
      })
      gsap.to(this.menus, {
        x: '-100%',
        ease: 'power2.in',
        duration: 0.3,
        onComplete: () => {
          gsap.set(this.el.querySelector('.menus-inner'), {
            x: '0%'
          })
          gsap.set(this.location, {
            y: '0%',
          })

          this.menus.classList.add('hidden')
          this.menus.classList.remove('flex')
          this.menuTimeline.seek(0).pause()
          if(window.bodyScrollBar){
            window.bodyScrollBar.start()
          }else{
            enablePageScroll()
          }
          window.pauseAnimation = false
        },
        onStart: () => window.pauseAnimation = true
      })
      gsap.to(this.location, {
        y: '100%',
        duration: 0.2,
        ease: 'power2.in'
      })
    }
  }

  menuAnim = () => {
    this.mainMenu = this.el.querySelector('.main-menu')
    this.secondaryMenu = this.el.querySelector('.secondary-menu')
    this.locationsMenu = this.el.querySelector('.location-wrapper')

    this.menuTimeline = gsap.timeline({ 
      paused: true,
      onStart: () => {
        this.menus.classList.remove('hidden')
        this.menus.classList.add('flex')
        window.pauseAnimation = true
      },
      onComplete: () => window.pauseAnimation = false
    })
    this.menuTimeline.fromTo(this.menus, {x: '-100%', backgroundColor: '#232425'},{
      backgroundColor: '#18191a',
      x: 0,
      duration: 0.6,
      ease: 'power2.out'
    })
    // this.menuTimeline.to(document.querySelector('#content-wrapper'), {
    //   x: '10%',
    //   autoAlpha: 0.6,
    //   ease: 'power2',
    //   duration: 0.6
    // }, '<')
    this.menuTimeline.to(this.topBar.querySelectorAll('.top-bar-item, .menu-text'), {
      autoAlpha: 0,
      x: 75,
      ease: 'power2.in',
      duration: 0.3
    }, '<')
    this.menuTimeline.fromTo(this.el.querySelectorAll('.menus-wrapper a'), {x: -75, autoAlpha: 0},{
      x: 0,
      duration: 0.3,
      ease: 'power2.out',
      stagger: 0.03,
      autoAlpha: 1
    }, '<0.4')
    this.menuTimeline.to(this.location, {
      y: 0,
      duration: 0.3,
      ease: 'power2.out'
    }, '<')
    this.menuTimeline.fromTo(this.locationsMenu.querySelectorAll('h3, a'), {x: -75, autoAlpha: 0}, {
      x: 0,
      duration: 0.3,
      ease: 'power2.out',
      stagger: 0.03,
      autoAlpha: 1
    }, '<')

    // this.menuTimeline.fromTo(this.el.querySelectorAll('.secondary-menu a, .social-links a'), {x: -75, autoAlpha: 0}, {
    //   x: 0,
    //   duration: 0.3,
    //   ease: 'power2.out',
    //   stagger: 0.03,
    //   autoAlpha: 1
    // }, '<0.2')
  }

  checkActiveLocation = () => {
    const activeLocation = Cookies.get('restaurant')
    if(activeLocation && activeLocation !== 'undefined'){
      const { id } = JSON.parse(activeLocation)
      document.querySelectorAll(`.location-menu-item`).forEach(link => {
        if(link.classList.contains(`location-menu-item-${id}`)){
          link.classList.add('active')
        }else{
          link.classList.remove('active')
        }
      })
    }
  }

  topBarHide = () => {
    ScrollTrigger.create({
      trigger: document.body,
      start: '72px top',
      end: 99999,
      onUpdate: ({direction, isActive}) => {
        const filters = document.querySelector('.entry-menu .filters')

        if (isActive) {
          this.topBar.classList.add('bg-dark-grey')
          this.topBar.classList.remove('pt-8','2xl:pt-16')
        }else{
          this.topBar.classList.remove('bg-dark-grey')
          this.topBar.classList.add('pt-8','2xl:pt-16')
        }
        
        if(isActive && direction > 0 && !this.topBar.classList.contains('reached-bottom')){
          this.topBar.classList.add('-translate-y-full')

          if(filters){
            filters.classList.remove(`!top-[83px]`)
          }
        }else{
          this.topBar.classList.remove('-translate-y-full')

          if(filters && filters.classList.contains('bg-dark-grey')){
            filters.classList.add(`!top-[83px]`)
          }else if(filters){
            filters.classList.remove(`!top-[83px]`)
          }
        }
      }
    })
  }

  update = () => {
    this.menuTimeline.kill()
    this.menuAnim()
  }

  setActiveGlobal = () => {
    const path = window.location.pathname
    const menuLinks = document.querySelectorAll('.main-menu-item, .top-bar-item')
    const currentLink = document.querySelectorAll(`.main-menu-item[href*="${path}"], .top-bar-item[href*="${path}"]`)
    
    if(currentLink && path !== '/'){
      menuLinks.forEach(link => {
        if(![...currentLink].some(cl => cl == link)) link.classList.remove('active')
      })
      currentLink.forEach(cl => cl.classList.add('active'))
    }else{
      menuLinks.forEach(link => {
        link.classList.remove('active')
      })
    }
  }
}